let apiEndpoint =
  typeof process !== 'undefined'
    ? process.env.CONTENT_API_ENDPOINT
    : typeof window !== 'undefined'
    ? window.ENV.CONTENT_API_ENDPOINT
    : '';

let apiKey =
  typeof process !== 'undefined'
    ? process.env.CONTENT_API_KEY
    : typeof window !== 'undefined'
    ? window.ENV.CONTENT_API_KEY
    : '';

export const fetcher = <TData, TVariables>(
  query: string,
  variables?: TVariables,
  options?: RequestInit['headers'],
) => {
  return async (): Promise<TData> => {
    const res = await fetch(apiEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ..._headers,
        ...options,
      },
      body: JSON.stringify({query, variables}),
    });

    const json = await res.json();
    if (json.errors) {
      const {message} = json.errors[0];
      throw new Error(message);
    }

    return json.data;
  };
};

/**
 * Default api key header will be overwritten in admin
 * with the relevant session key. In BestSelling, anonymous id headers
 * will be added on
 */
let _headers: Record<string, string> = {
  'x-api-key': apiKey,
};

export class APIHeaders {
  static getHeaders() {
    return {..._headers};
  }

  static setHeaders(headers: Record<string, string>) {
    _headers = headers;
  }

  static setKeys(endpoint: string, key: string) {
    apiEndpoint = endpoint;
    apiKey = key;
    _headers['x-api-key'] = key;
  }

  static getApiKey() {
    return typeof process !== 'undefined'
      ? process.env.CONTENT_API_KEY
      : typeof window !== 'undefined'
      ? window.ENV.CONTENT_API_KEY
      : '';
  }
}
